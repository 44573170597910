<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  تعديل  تعميم">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="title"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  العنوان </label>
                    <b-form-input
                      id="input-default"
                      v-model="title"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ البداية </label>
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ النهاية </label>
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="title"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  النص </label>
                    <b-form-textarea
                      id="input-default"
                      v-model="body"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> ملاحظة</label>
                    <b-form-input
                      id="input-default"
                      v-model="notes"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                class="mb-2"
              >

                <div class="border rounded p-2">
                  <h4 class="mb-1">
                الصورة
                  </h4>
                  <b-media
                    no-body
                    vertical-align="center"
                    class="flex-column flex-md-row"
                  >
                    <div class="d-inline-block">
                      <b-media-aside>
                        <b-img
                          ref="previewEl"
                          :src="img"
                          height="110"
                          width="170"
                          class="rounded mr-2 mb-1 mb-md-0 col-12"
                        />
                      </b-media-aside>

                    </div>
                    <b-media-body>
                      <b-card-text class="mt-1" />
                      <div class="d-inline-block mt-1">
                        <b-form-group
                          label=" Image"
                          label-for="blog-tab-title"
                          class="mb-2"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Tab Image"
                          >
                            <b-form-file
                              ref="refInputEl"
                              v-model="img"
                              accept=".jpg, .png, .gif, .jpeg"
                              placeholder="Choose file"
                              @input="inputImageRenderer"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                      </div>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
              
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="addPacts"
                >
                
                  <span class="align-middle"> تعديل </span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BMedia,
  BFormInvalidFeedback,
  BMediaAside,
  BImg,
  BMediaBody,
  BAvatar,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { setgroups } from 'process'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {

  components: {
    flatPickr,
    ToastificationContent,
    BAvatar,
    required,
    BMedia,
    BImg,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      file: '',
      body: '',
      title: '',
      img: '',

      columns: [
        {
          label: 'الموظف ',
          field: 'created_by',
        },
        {
          label: 'تاريخ البداية ',
          field: 'start_date',
        },
        {
          label: 'تاريخ النهاية ',
          field: 'end_date',
        },
        {
          label: ' العنوان ',
          field: 'title',
        },

        {
          label: 'الخيارات',
          field: 'id',
          sortable: true,
        },
      ],
      rows: [],
      start_date: '',
      end_date: '',
      notes: '',
      body: '',
      id: '',
      searchTerm: '',
      optionerewardType: [
      ],
      item: '',
      optionitem: [],
      optionemploye: [],
    }
  },

  created() {
    this.getPacts()
  },

  methods: {

    deletePacts(id) {
      this.$swal({
        icon: 'warning',
        title: 'هل انت متأكد من الحذف',
        confirmButtonText: 'موافق',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'لا',
        },
      }).then(result => {
        if (result.value) {
          const url = `/api/v1/inform/${id}`
          this.$http.delete(url).then(res => {
            //console.log(res)
            this.getPacts()
            this.$swal({
              icon: 'warning',
              title: 'تم الحذف  بنجاح',
              confirmButtonText: 'موافق',
              cancelButtonText: 'الغاء',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'لا',
              },
            })
          })
        }
      })
    },
    addPacts() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              const formData = new FormData()
              formData.append('end_date', this.end_date)
              formData.append('start_date', this.start_date)
              formData.append('title', this.title)
              formData.append('body', this.body)
              formData.append('img', this.img)
              formData.append('notes', this.notes)
              formData.append("_method", "put");
              //console.log('formData', formData)
              this.$http.post(`/api/v1/inform/${this.$route.params.id}`, formData).then(res => {
                //console.log(res)
              
                this.$swal({
                  title: '',
                  text: 'تم تعديل   بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    getPacts() {
      this.$http.get(`/api/v1/inform/${this.$route.params.id}`).then(res => {
        //console.log('inform', res.data.data)
        this.title = res.data.data.title
        this.start_date = res.data.data.start_date
        this.end_date = res.data.data.end_date
        this.body = res.data.data.body
        this.notes = res.data.data.notes
        this.img = res.data.data.image
      })
    },

    //
  },

  setup(props) {
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      //console.log('dff',base64)
      previewEl.value.src= base64
    })
    return {
      refInputEl,
      Tabimage,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
